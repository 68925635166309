<template>
  <div class="culture">
    <div class="site-content">
      <section-title>
        <div class="culture-header">
          <div class="title">企业文化</div>
        </div>
      </section-title>
      <!---说明--->
      <div class="statement">
        <p>
          根据赵州饼目前的经营者外祖父家的《周氏家谱》记载，他的祖先来自山西，周氏“原籍山西省平阳府洪洞县老鸹窝人氏”，于“大明成祖永乐二年（1404年）有旨移民，遂迁至直隶正定府赵州西襄杨村”。这个村子南边有条河，名叫湘河，现在叫江沟，由此村名演变成了西湘洋。西湘洋村地处赵州大石桥正南，距离县城12华里，在古代赵州通往邻县柏乡的官道旁边。姥爷的祖先在此以务农为生，农忙时种地，农闲时蒸卷子（也就是馒头），用独轮车推着笸箩到官道上叫卖。有一年冬天，天近傍晚，官道上从南向北来了一个老僧人，他是赵州柏林禅寺的厨师，外出办事在路上崴了脚。姥爷的祖先信佛，乐善好施，见到老僧人走路困难，就用独轮车推着把他送回了寺里。
        </p>
        <img
          class="culture-img"
          src="https://s4.ax1x.com/2022/02/23/bPp5EF.jpg"
          alt=""
        />
        <p>
          从此以后，外祖父的先人就和老厨师成了熟人，每次到柏林禅寺烧香拜佛都去看他。时间久了，老厨师见外祖父的先人厚道实在善良，就把柏林禅寺做饼的手艺教给了他，还送给他一个做月饼的模子。这模子代代相传，现在我爸妈的手上，模子上有刻款，是乾隆壬午年，也就是公元1762年，到现在已经259年了。从此，姥爷的先人平时蒸卷子卖，到了八月十五和过年过节，就做饼卖。我家的饼非常特殊，坯子是雪白的，烤熟后就变成了暗红色，人们都叫它自来红。
        </p>
        <img
          class="culture-img"
          src="https://s4.ax1x.com/2022/02/23/bPCBWj.jpg"
          alt=""
        />
        <p>
          外祖父生于1937年，他的父亲叫周连喜，祖父叫周焕德。周焕德的爷爷，大概在清朝咸丰年间（1851-1861年），为了把买卖做大，就从西湘洋村搬到了现在的大石桥村。这里地处举世闻名的赵州大石桥南头，是交通要道，往来客人多，到赵州城里买卖十分加便利。由于常年在赵州大石桥头卖饼，并且我家的饼与别人家的饼明显不同，所以来往的客人们都叫我家的饼为“赵州饼”。
        </p>
        <img
          class="culture-img"
          src="https://s4.ax1x.com/2022/02/23/bPCIp9.jpg"
          alt=""
        />
        <p>
          外祖父从小就跟着他的父亲蒸白卷子做自来红饼，1950年厄运降临，父母相继离世，年仅14岁的姥爷，独自承担起了这份沉重的家业，从买麦子换油磨面，到制作销售，挑着六七十斤重的卷子到赵县城里去叫卖，以此养活三个年幼的妹妹。1955年他光荣参军，成了一名解放军战士，在部队里也没丢了手艺，经常帮厨做饼蒸馒头。1959年退伍复员，由于有做饼的手艺在身，被组织分配到赵县食品厂工作，并担任了该厂的技术科长，期间曾分别被派到广州、廊坊、石家庄、北京、杭州等地的食品厂考察学习，由此成了厂里的生产技术权威。
        </p>
        <p>
          1991年赵县食品厂改制停产，外祖父根据市场需求，带领我爸妈创办了“桥头第一家”个体糕饼店，继续生产祖传的自来红赵州饼和小麻花、绿豆饼、桃酥等糕点，深受群众喜爱，直到2013年去世。之后，我父亲张胜利继承了姥爷的技术和店铺，积极挖掘传统工艺，不断扩大生产经营规模，2019年以经营者父亲之名“胜利”的谐音，注册了“好生利”赵州饼商标，走上了一条传承赵县独特的禅味糕饼文化，弘扬赵县糕饼产业，创立著名糕饼品牌的创业之路。2020年，我家全年生产销售各种糕点35吨，其中自来红赵州饼28吨，在赵县及周边县区，具有相当的知名度。
        </p>
        <hr />

        <img
          class="cultureImg"
          src="https://s4.ax1x.com/2022/02/22/HxtVRP.jpg"
          alt=""
        />
      </div>
      <!----->
    </div>
  </div>
</template>

<script>
import sectionTitle from "@/components/section-title";
import Quote from "@/components/quote";
export default {
  name: "culture",
  data() {
    return {
      websiteInfo: {},
      list: [],
    };
  },
  components: {
    Quote,
    sectionTitle,
  },
  methods: {
   
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.culture {
  padding-top: 40px;
}
.culture-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  font-weight: 400;
  .title {
    font-size: 20px;
  }
  .apply:hover {
    color: #ff6d6d;
  }
}
.statement {
  margin: 30px 0 20px 0;
  a {
    color: #ff6d6d;
  }
  p {
    line-height: 2rem;
  }
  .culture-img {
    width: 50%;
  }
  .cultureImg {
    width: 100%;
  }
}
hr {
  margin: 40px 0;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 109, 109, 0.75),
    rgba(0, 0, 0, 0)
  );
}
.culture-list {
  width: 100%;
  .culture-item {
    display: inline-block;
    width: 30%;
    /*height: 100px;*/
    margin: 0 5% 20px 0;
    padding: 10px 30px;
    border: 1px solid #ececec;
    border-radius: 3px;
    &:hover {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    .site-name,
    .site-detail {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 10px;
      line-height: 1.5rem;
    }
    .site-name {
      color: #8fd0cc;
      border-bottom: 1px dotted #ececec;
    }
    .site-detail {
      font-size: 13px;
      padding-top: 10px;
    }
  }
}
/*******/
@media (max-width: 800px) {
  .culture-header {
    margin-top: 0;
  }
  .culture-list {
    .culture-item {
      width: 45%;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(3n) {
        margin-right: 5%;
      }
    }
  }
}
@media (max-width: 600px) {
  .culture-list {
    .culture-item {
      display: block;
      width: 90%;
      margin: 0 auto 20px auto;
      &:nth-of-type(2n) {
        margin-right: auto;
      }
      &:nth-of-type(3n) {
        margin-right: auto;
      }
    }
  }
}
</style>
